import { FC } from 'react';
import Layout from '@/components/layout/Layout';
import HomeNewBooks from '@/components/ui/home-new-books/HomeNewBooks';
import HomePopularBooks from '@/components/ui/home-popular-books/HomePopularBooks';
import HomeSearchBlock from '@/components/ui/home-search-block/HomeSearchBlock';
import HomeInfoBlock from '@/components/ui/home-info-block/HomeInfoBlock';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';

interface IHomePage {
  popularBooks: IBookPage;
  newBooks: IBookPage;
  oneNewBook: IBooksPageData;
  onePopularBook: IBooksPageData;
}

const Home: FC<IHomePage> = ({ popularBooks, onePopularBook, newBooks, oneNewBook }) => {
  //seo block
  const SEO_TITLE = 'Электронная библиотека - скачать книги бесплатно, журналы, словари любых авторов';
  const SEO_DESCRIPTION = 'Коллекция бесплатных книг в электронном варианте :: BookZ.ru';

  return (
    <Layout title={SEO_TITLE} description={SEO_DESCRIPTION} canonicalHref="/">
      <HomeNewBooks oneNewBook={oneNewBook} newBooks={newBooks} />
      <HomePopularBooks onePopularBook={onePopularBook} popularBooks={popularBooks} />
      <HomeSearchBlock />
      <HomeInfoBlock />
    </Layout>
  );
};

export default Home;
